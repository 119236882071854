import { Accordion, Container, Title, Text, List, Anchor } from "@mantine/core";
import styled from "styled-components";
import React from "react";

const StyledFAQSection = styled.section`
  background-color: #1a1a1a; /* Dark background color */
  padding: 20px 0 180px 0; /* Adjusted top and bottom padding */
`;

const StyledAccordionItem = styled(Accordion.Item)`
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  color: #eeeeee; /* Light text color */
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none; /* Remove border */

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const Questions = [
  {
    title: "What is Nerval?",
    content:
      "Nerval is a fully-managed service designed to sync your team's data to your LLM applications. It ensures your data stays fresh and updated, allowing you to focus on building your product. Nerval is ideal for developers, data scientists, and enterprises looking to leverage their data efficiently.",
  },
  {
    title: "Who is Nerval for and what are the use-cases?",
    content:
      "Nerval is tailored for developers, data scientists, and enterprises that require real-time data synchronization for their LLM applications. It's perfect for visualizing data structures, debugging, and understanding complex datasets. Additionally, it aids in creating comprehensive documentation and presentations.",
  },
  {
    title: "Is my data secure with Nerval?",
    content:
      "Yes, Nerval prioritizes your data privacy. We do not store your data unless you manually upload it. All data processing occurs on your device to create visualizations, ensuring complete privacy and security.",
  },
  {
    title: "What are the advantages of the premium plan?",
    content: (
      <>
        The premium plan offers:
        <List mt="lg">
          <List.Item>Support for larger datasets</List.Item>
          <List.Item>Advanced data comparison tools</List.Item>
          <List.Item>Compact and customizable visualization styles</List.Item>
          <List.Item>Enhanced search functionality</List.Item>
          <List.Item>AI-powered data filtering</List.Item>
          <List.Item>Direct data editing on graphs and tree views</List.Item>
        </List>
        <Text mt="sm" inherit>
          For more details, please visit the <Anchor href="#pricing">pricing page</Anchor>.
        </Text>
      </>
    ),
  },
];

export const FAQ: React.FC = () => {
  return (
    <StyledFAQSection>
      <Container size="md">
        <Title
          order={2}
          fz={{
            sm: 32,
            md: 42,
          }}
          fw={600}
          mt={150}
          mb={60}
          style={{ textAlign: "center", color: "#eeeeee" }}
        >
          Frequently Asked Questions
        </Title>
        <Accordion
          variant="separated"
          styles={{
            panel: {
              background: "#2a2a2a",
              color: "#eeeeee",
            },
            label: {
              color: "#eeeeee",
              fontWeight: 500,
            },
            item: {
              background: "#2a2a2a",
              color: "#eeeeee",
              overflow: "hidden",
              border: "none",
              borderRadius: 12,
              fontWeight: 300,
            },
          }}
        >
          {Questions.map(({ title, content }) => (
            <StyledAccordionItem key={title} value={title}>
              <Accordion.Control>{title}</Accordion.Control>
              <Accordion.Panel>{content}</Accordion.Panel>
            </StyledAccordionItem>
          ))}
        </Accordion>
      </Container>
    </StyledFAQSection>
  );
};